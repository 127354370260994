import React from 'react';

import ImageQuote from '../ImageQuote/ImageQuote';

import { Wrapper, Title } from './styled';

const Reviews = ({ reviews }) => {
  return (
    <Wrapper>
      <Title>Homeowners who love us, LOVE US.</Title>
      {reviews.map((review, index) => (
        <ImageQuote
          key={index}
          title={review.title}
          image={review.image}
          text={review.text}
          name={review.name}
          position={review.position}
          linkedin={review.linkedin}
          alt={review.alt}
        />
      ))}
    </Wrapper>
  );
};

export default Reviews;
