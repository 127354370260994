import React, { useEffect } from 'react';
import { scroller } from 'react-scroll';
import mixpanel from 'mixpanel-browser';

import { analyticsPageTypes, pageTypes } from 'common/enums/pages';
import { Button, Contacts, GetApp, Hero, MainContent } from 'components';

import loveOurPlanetBG1x from 'static/images/hero/hero-love-our-planet-0.5x.webp';
import loveOurPlanetBG2x from 'static/images/hero/hero-love-our-planet-2x.webp';
import SatadruPhoto from 'static/images/quotes/satadru-rounded-2.webp';
import AlexPhoto from 'static/images/quotes/alex-rounded.webp';

import {
  ContactsSection,
  DownloadAppHeading,
  DownloadWrapper,
  JustStartingSection,
  MainSectionContainer,
  TreeButton,
  TreeHeading,
  TreeSubheading
} from './styled';
import styles from 'common/enums/styles';

import TextContent from './TextContent';
import HowItWorks from './HowItWorks';
import ScrollToPlantTree from './ScrollToPlantTree';

const contacts = [
  {
    fullName: 'Satadru Sengupta',
    position: 'CEO',
    email: 'satadru@hellodobby.com',
    photo: SatadruPhoto,
    alt: 'Satadru Sengupta, Co-founder and CEO of Dobby home services app.'
  },
  {
    fullName: 'Alex Fontova',
    position: 'COO',
    email: 'alex@hellodobby.com',
    photo: AlexPhoto,
    alt: 'Alex Fontova, Co-founder and COO of Dobby home services app.'
  }
];

const LoveOurPlanet = ({ history, location }) => {
  useEffect(() => {
    document.title =
      'Dobby | The most future-forward home services and renovation company';
  }, [history]);

  useEffect(() => {
    if (location?.hash?.slice(1)) {
      setTimeout(() => scrollTo(location.hash.slice(1)), 100);
    } else {
      window.scrollTo(0, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      mixpanel.time_event('Page views Love Our Planet');
    } catch {}
    return function cleanup() {
      mixpanel.track('Page views Love Our Planet', {
        'Page Name': analyticsPageTypes.LOVE_OUR_PLANET
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollTo = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset:
        window.innerWidth >= 1024
          ? -styles.layout.MENU_HEIGHT
          : -styles.layout.MOBILE_MENU_HEIGHT
    });
  };

  const scrollToHowItWorks = () => {
    scrollTo('plant-a-tree');
  };

  const downloadApp = () => {
    const downloadLink =
      'https://hellodobby.page.link/?link=https%3A%2F%2Fhellodobby.com&ofl=https%3A%2F%2Fapps.apple.com%2Fus%2Fapp%2Fdobby-for-your-home%2Fid1445563710&apn=com.halosassistant&afl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.halosassistant&ibi=com.halos.insurance.HalosAssistant&isi=1445563710';
    window.open(downloadLink);
  };

  return (
    <MainContent>
      <MainSectionContainer>
        <Hero
          title={'Our Planet, Our Home'}
          subTitle="How Dobby is leading the way for the climate change initiatives in the $500B home services industry with YOUR help"
          imageSrc={loveOurPlanetBG2x}
          placeholderSrc={loveOurPlanetBG1x}
          backgroundType={pageTypes.LOVE_OUR_PLANET}></Hero>
      </MainSectionContainer>
      <TextContent />
      <JustStartingSection>
        <TreeHeading>Start small, grow tall.</TreeHeading>
        <TreeSubheading>
          Start a new job and we will plant a tree for you.
          <span role="img" aria-label="tree">
            🌲
          </span>
        </TreeSubheading>
        <TreeButton>
          <Button title="Plant a tree" onClick={downloadApp} />
        </TreeButton>
      </JustStartingSection>
      <ContactsSection>
        <Contacts contacts={contacts} page="our-planet" />
      </ContactsSection>
      <HowItWorks />
      <DownloadWrapper>
        <DownloadAppHeading>Download Dobby app</DownloadAppHeading>
        <GetApp
          backgroundColor={styles.colors.WHITE}
          entryPoint={analyticsPageTypes.PLANT_ONE_TREE}
          mobileOnly
          isCentered
          light
        />
      </DownloadWrapper>
      <ScrollToPlantTree onClick={scrollToHowItWorks} />
    </MainContent>
  );
};

export default LoveOurPlanet;
