import React from 'react';
import poster from 'static/images/backgrounds/landing-video-poster.webp';

const Player = ({ autoPlay }) => (
  <video
    width="100%"
    controls
    poster={poster}
    autoPlay={autoPlay ? 'autoplay' : null}>
    <source
      src="https://dobby-product-static.s3.amazonaws.com/video/dobby-Intro.mp4"
      type="video/mp4"
    />
    Your browser does not support HTML video.
  </video>
);

export default Player;
