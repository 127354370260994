import React from 'react';

import { Text } from 'components';
import styles from 'common/enums/styles';

import icon from 'static/images/icons/quote.svg';
import iconBlue from 'static/images/icons/quote-blue.svg';

import LinkedInIcon from '../LinkedInIcon/LinkedInIcon';
import {
  Section,
  Title,
  TextWrapper,
  TextContainer,
  Container,
  ImageWrapper,
  Image,
  Position,
  Name,
  NameBox,
  IconUp,
  IconDown,
  Delimeter
} from './styled';

const ImageQuote = ({
  title,
  image,
  text,
  name,
  position,
  linkedin,
  inverted,
  alt
}) => (
  <Section inverted={inverted}>
    <Container>
      <ImageWrapper>
        <Image src={image} alt={alt || 'person'} />
        <LinkedInIcon link={linkedin} />
      </ImageWrapper>
      <TextContainer>
        <Title inverted={inverted}>{title}</Title>
        <NameBox>
          <Name inverted={inverted}>{name}</Name>
          <Position inverted={inverted}>{position}</Position>
        </NameBox>
        <TextWrapper>
          <IconUp src={inverted ? iconBlue : icon} />
          <Text description2 color={inverted ? styles.colors.WHITE_NEW : null}>
            {text}
          </Text>
          <IconDown src={inverted ? iconBlue : icon} />
        </TextWrapper>
      </TextContainer>
      <Delimeter />
    </Container>
  </Section>
);

export default ImageQuote;
