import AlexPhoto from 'static/images/quotes/alex-manager.webp';
import growTogetherStepOne from 'static/images/icons/grow-together-step-1.svg';
import growTogetherStepTwo from 'static/images/icons/grow-together-step-2.svg';
import growTogetherStepThree from 'static/images/icons/grow-together-step-3.svg';

export const contacts = {
  ALEX: {
    FULLNAME: 'Alex Fontova',
    POSITION: 'COO',
    EMAIL: 'alex@hellodobby.com',
    PHOTO: AlexPhoto
  }
};

export const providerSteps = {
  SHARE: {
    TYPE: 0,
    IMAGE: growTogetherStepOne,
    TITLE: 'Share your link',
    TEXT:
      'Invite quality service providers to join Dobby by sharing your personal referral link with them through emails, texts, or social media.'
  },
  SIT_BACK: {
    TYPE: 1,
    IMAGE: growTogetherStepTwo,
    TITLE: 'Sit back',
    TEXT:
      'We will begin the vetting and onboarding processes for your invitees and make sure your referrals are in good hands.'
  },
  PAID: {
    TYPE: 2,
    IMAGE: growTogetherStepThree,
    TITLE: 'Get paid',
    TEXT:
      'When your referral completes their first job, you get $100! They will get $50 after their first job, plus $50 after their second job!'
  }
};

export const homeOwnersteps = {
  SHARE: {
    TYPE: 0,
    IMAGE: growTogetherStepOne,
    TITLE: 'Share your link',
    TEXT:
      'Invite your neighbors to join Dobby by sharing your personal referral link with them through emails, texts, or social media.'
  },
  SIT_BACK: {
    TYPE: 1,
    IMAGE: growTogetherStepTwo,
    TITLE: 'Sit back',
    TEXT:
      'We will track the status of your invites and make sure your referrals are in good hands.'
  },
  REWARD: {
    TYPE: 2,
    IMAGE: growTogetherStepThree,
    TITLE: 'Get rewarded',
    TEXT:
      'When your friend completes their first maintenance job, you get a $50 credit in your account. You can make up to $500 in Dobby credit, just from spreading the word!'
  }
};
