// FAQ page using Acordeon component

import React, { useEffect } from 'react';
import { scroller, Element } from 'react-scroll';

import { useTrackPageViews } from 'hooks';
import { Hero } from 'components';
import { DOCUMENT_TITLE } from 'common/constants/html';
import { analyticsPageTypes, pageTypes } from 'common/enums/pages';

import hero from 'static/images/hero/hero-ho@2x.webp';
import heroPlaceholder from 'static/images/hero/hero-ho@0.75x.webp';
import mobileBackground from 'static/images/hero/hero-ho-m.webp';

import Reviews from './components/Reviews/Reviews';
import { dobbyHOValues, reviews } from './content';
import {
  Section,
  Content,
  heroBackground,
  ValuesWrapper,
  Title,
  Subheader,
  HOValue
} from './styled';

const DobbyHO = ({ history }) => {
  useTrackPageViews({ page: analyticsPageTypes.DOBBY_HO });

  const scrollTo = section => {
    scroller.scrollTo(section, {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -150
    });
  };

  useEffect(() => {
    document.title = DOCUMENT_TITLE;
    window.scrollTo(0, 0);
    if (history.location.state?.section) {
      scrollTo(history.location.state.section);
    }
  }, [history.location.state]);

  return (
    <Section>
      <Hero
        background={heroBackground}
        mobileBackground={mobileBackground}
        // title="Are you a Dobby Homeowner?"
        imageSrc={hero}
        placeholderSrc={heroPlaceholder}
        backgroundType={pageTypes.DOBBY_HO}
        desktopHeight="75"
        alt="Homeowners featured by Dobby home services app."
      />
      <Content>
        <Element name="scrollBelowSecondSection" />
        <Title>
          Not everyone is a Dobby Homeowner.{'\n'}
          They are the special ones.
        </Title>
        <ValuesWrapper>
          {dobbyHOValues.map((section, index) => (
            <>
              <Subheader key={index}>{section.title}</Subheader>
              {section.values.map((value, index) => (
                <HOValue key={index}>{value}</HOValue>
              ))}
            </>
          ))}
          <Subheader>Are you a Dobby Homeowner?</Subheader>
        </ValuesWrapper>
        <Reviews reviews={reviews} />
      </Content>
    </Section>
  );
};

export default DobbyHO;
