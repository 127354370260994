import { call, takeLatest, put } from 'redux-saga/effects';
import api_constants from 'common/enums/api';
import actionTypes from 'common/enums/actionTypes';
import Api from 'services/ApiService';

function* getArticles({ payload }) {
  // const page = payload || 1;
  console.log('payload', payload);
  const page = payload.page || 1;

  try {
    const response = yield call(
      Api.cmsGet,
      `?populate=*&sort=createdAt:desc&pagination[page]=${page}&pagination[pageSize]=20`
    );

    if (response.data) {
      const rawData = response.data.map(item => ({
        ...item.attributes,
        id: item.id
      }));

      const data = {
        data: rawData.sort((a, b) => a.publishedAt - b.publishedAt),
        meta: response.meta
      };

      yield put({
        type: actionTypes.GET_ARTICLES_SUCCESS,
        payload: data
      });
    }
  } catch (err) {
    yield put({
      type: actionTypes.GET_ARTICLES_ERROR,
      payload: 'Something went wrong. Try again later'
    });
  }
}

export default function* watchGetArticles() {
  yield takeLatest(actionTypes.GET_ARTICLES_FETCH, getArticles);
}
