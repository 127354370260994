import React from 'react';

import { Text } from 'components';
import styles from 'common/enums/styles';

import icon from 'static/images/icons/quote.svg';
import iconWhite from 'static/images/icons/quote-white.svg';

import {
  Section,
  TitleWrapper,
  Title,
  TextWrapper,
  Container,
  ImageWrapper,
  Image,
  Position,
  Name,
  NameBox,
  QuoteNameBox,
  QuoteName,
  QuoteSub,
  IconUp,
  IconDown,
  textStyle
} from './styled';

const ImageQuote = ({
  title,
  image,
  text,
  name,
  position,
  quoteName,
  quoteSub,
  inverted,
  alt,
  longTitle = false
}) => (
  <Section inverted={inverted}>
    <TitleWrapper longTitle={longTitle}>
      <Title inverted={inverted} longTitle={longTitle}>
        {title}
      </Title>
    </TitleWrapper>
    <Container>
      <ImageWrapper>
        <Image src={image} alt={alt} />
      </ImageWrapper>
      <TextWrapper>
        <IconUp src={inverted ? iconWhite : icon} />
        <Text
          description2
          color={inverted ? styles.colors.WHITE_NEW : null}
          style={textStyle}>
          {text}
        </Text>
        <IconDown src={inverted ? iconWhite : icon} />
      </TextWrapper>
      <NameBox quoteName={Boolean(quoteName)}>
        <Name inverted={inverted}>{name}</Name>
        <Position inverted={inverted}>{position}</Position>
      </NameBox>
      {quoteName && (
        <QuoteNameBox>
          <QuoteName inverted={inverted}>{quoteName}</QuoteName>
          <QuoteSub inverted={inverted}>{quoteSub}</QuoteSub>
        </QuoteNameBox>
      )}
    </Container>
  </Section>
);

export default ImageQuote;
