import starsImage from 'static/images/icons/stars.svg';
import medalImage from 'static/images/icons/medal.svg';
import likeImage from 'static/images/icons/like.svg';
import quoteImage from 'static/images/quotes/quote-image-satadru.webp';

export const items = {
  INFLUENCE: {
    TYPE: 0,
    IMAGE: medalImage,
    TITLE: 'We select the best',
    TEXT:
      'We work with small businesses in the community based on stellar reviews and references.',
    ALT:
      'Get the best home service providers near you, verified and trusted contractors for home improvement'
  },
  TIME: {
    TYPE: 1,
    IMAGE: likeImage,
    TITLE: 'We value our pros',
    TEXT:
      'From sharing meals to regular calls, we communicate with and get to know our Pros personally.',
    ALT: 'We value our pros, trusted home service providers.'
  },
  MANAGER: {
    TYPE: 2,
    IMAGE: starsImage,
    TITLE: 'Back office as a service',
    TEXT:
      'Dobby is a partner to our pros. From marketing to technology to customer success to payment, we give our pros full back office support for free.',
    ALT: 'Back office support for home service pros.'
  }
};

export const imageQuoteContent = {
  IMAGE: quoteImage,
  TITLE: {
    MOBILE:
      "We're not a lead-selling business. We make money ONLY WHEN you make money.",
    DESKTOP:
      "We're not a lead-selling business.\nWe make money ONLY WHEN you make money."
  },
  TEXT:
    "We take pride in serving you, our service providers. On Dobby, you will spend zero dollars on buying leads. Our AI-powered app will help you do more faster. Our customer success team will work as your back office and 24/7 dispatcher for you. All for no cost to you. And all that we ask for is 5-star quality craftsmanship and clear communication. If that's you, let's work together!",
  NAME: 'Satadru Sengupta',
  POSITION: 'Co-founder and CEO',
  ALT: 'Satadru Sengupta, Co-founder and CEO of Dobby home services app.'
};
