export const dobbyHOValues = [
  {
    title: 'Dobby Homeowners value their time.',
    values: [
      'Time with family.',
      'Time with friends.',
      'Time with the furry ones.'
    ]
  },
  {
    title: 'They believe in something bigger than themselves.',
    values: ['At their work.', 'As new parents.', 'In their community.']
  },
  {
    title: 'They celebrate great products.',
    values: [
      'High-quality service pros.',
      'Informed decisions.',
      'Magical experience.',
      'A beautiful home.'
    ]
  },
  {
    title: 'They choose companies that are making history.',
    values: [
      'By saving our time.',
      'By making our lives 100x better.',
      'By reimagining an unloved industry with new thinking.',
      'By supporting our small business communities.',
      'And by taking care of our planet.'
    ]
  }
];

export const reviews = [
  {
    name: 'Scott',
    position: 'Founder turned Investor',
    text:
      'Dobby gives me my time back. Their pros are excellent, their customer success team is top-notch, and the app makes everything seamless. If I built another company today, it would be Dobby.',
    image: 'https://dobby-product-static.s3.amazonaws.com/website/scott.webp',
    linkedin: 'https://www.linkedin.com/in/scotthintz/',
    alt: 'Homeowner Scott, sharing a positive review for Dobby home services.'
  },
  {
    name: 'Stephanie',
    position: 'U.S. Attorney',
    text:
      'For me, the most annoying part of getting things done around our house is having to research all the professionals in the area who provide the service, contact them, get quotes, and actually schedule the work. Dobby does all of the hard parts for you! We have used this app for multiple projects — tree stump removal, TV mounting, and a plumbing project — and have had fantastic and quick service every time!',
    image:
      'https://dobby-product-static.s3.amazonaws.com/website/stephanie.webp',
    linkedin: 'https://www.linkedin.com/in/stephanie-berger-914b448/',
    alt:
      'Homeowner Stephanie, U.S. Attorney, sharing a review for Dobby home services app.'
  },
  // {
  //   name: 'Cameron',
  //   position: 'Civic Research',
  //   text:
  //     "We joined the Dobby family just as we purchased our first home last year, and can't imagine going through this homeowning experience without them. Now that we have our 18-month-old son, I have even less time to worry about my home. They have been the best and we'll be loyal customers moving forward.",
  //   image: 'https://dobby-product-static.s3.amazonaws.com/website/cameron.webp',
  //   linkedin: 'https://www.linkedin.com/in/cameron-sullivan-0ba1b1154/'
  // },
  {
    name: 'Gene Sung',
    position: 'Real estate agent',
    text:
      'Dobby is a game changer. I love it as a homeowner, but also as a top real estate agent in DC. I’ve been really impressed with the customer success and the variety of things I can get done. I use Dobby both on my own home and I share it with my clients.',
    image:
      'https://dobby-product-static.s3.amazonaws.com/website/gene-sung.webp',
    linkedin: 'https://www.linkedin.com/in/eugenesungdc/',
    alt:
      'Homeowner Gene Sung, real estate agent, sharing a positive review for Dobby home services app.'
  },
  {
    name: 'Patrick',
    position: 'Private Equity',
    text:
      'Dobby has managed plumbing, roofing, window, HVAC, and electrical/lighting projects for us in the past several months and the experience has been so much better than when we managed those individual vendors separately. Dobby also assists with the quality control, issue remediation, and price transparency aspects of those various contractors.',
    image: 'https://dobby-product-static.s3.amazonaws.com/website/patrick.webp',
    linkedin: 'https://www.linkedin.com/in/patrickquay/',
    alt:
      'Homeowner Patrick, sharing a positive review for Dobby home services app.'
  },
  {
    name: 'Pamela',
    position: 'Harvard Law',
    text:
      "My Dobby Pro was (1) cheaper than other businesses in the area and (2) incredibly knowledgeable, efficient, and patient in comparison to other companies I've worked with (outside of Dobby). I also like the fact that all the handymen come vetted and reviewed. Makes me feel safer as a woman who lives alone. My long-winded way of saying this app is a godsend.",
    image: 'https://dobby-product-static.s3.amazonaws.com/website/pamela.webp',
    linkedin: 'https://www.linkedin.com/in/pamela-amaechi-1977a162/',
    alt:
      'Homeowner Pamela, sharing a positive review for Dobby home services app.'
  },
  {
    name: 'Heidi',
    position: 'Mental Health Professional',
    text:
      'As a busy business owner and parent, Dobby has become essential in the running of my house. From improvement projects to emergencies, they have you covered with communicative and respectful vetted professionals to tackle whatever has been lingering on the to-do list. No more back and forth with contractors or unreturned calls!',
    image: 'https://dobby-product-static.s3.amazonaws.com/website/heidi.webp',
    linkedin: 'https://www.linkedin.com/in/heidi-vanderwerff-b602221b6/',
    alt:
      'Homeowner Heidi, sharing a positive review for Dobby home services app.'
  },
  {
    name: 'Brian',
    position: 'Economic Consulting',
    text:
      'For a busy family like ours, whose house is at the center of our activity, Dobby has been essential. Dobby has allowed us to walk away from the big, unreliable vendors and unhelpful matchmaking sites we used to rely on.Now we get connected with the right kind of service providers - those who care about our needs and their work product - consistently. And their customer success team makes us feel supported throughout the process!',
    image: 'https://dobby-product-static.s3.amazonaws.com/website/brian.webp',
    linkedin: 'https://www.linkedin.com/in/brianmcclelland1/',
    alt:
      'Homeowner Brian, sharing a positive review for Dobby home services app.'
  }
];
