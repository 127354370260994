import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AutocompleteList,
  AutocompleteListItem,
  BackgroundOpacity,
  ColoredHeader,
  FormWrapper,
  HeaderText,
  HeaderTitle,
  HeaderWrapper,
  IconLoader,
  ImageBackground,
  InputGroup,
  InputPhoneWrapper,
  InputWrapper,
  LoaderScreen,
  ScreenContentWrapper,
  SendButtonWrapper,
  Spacer,
  Wrapper,
  WrapperCenter
} from './styled';
import { Button, Input } from 'components';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import checkThePriceBackground from 'static/images/backgrounds/checkThePriceRight.webp';
import mixpanel from 'mixpanel-browser';
import { analyticsPageTypes, captchaActions } from '../../common/enums/pages';
import { CAPTCHA_SITE_KEY } from '../../common/constants/html';
import { useDispatch, useSelector } from 'react-redux';
import { InputPrefix } from '../Home/components/CommunityForm/styled';
import { Controller, useForm } from 'react-hook-form';
import StyledInput from '../../components/Input/styled';
import { askPlanningMode } from '../../store/actions/user';
import { askPlanningModeLoading } from '../../store/reducers/planningMode';
import iconLoader from 'static/images/icons/planningModeLoader.svg';
import * as yup from 'yup';
import MobileAutocomplete from '../../components/MobileAutocomplete/MobileAutocomplete';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { device } from '../../common/enums/devices';
import { useScrollPosition } from 'hooks';

const useYupValidationResolver = validationSchema =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });

        return {
          values,
          errors: {}
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    [validationSchema]
  );

const validationSchema = yup.object({
  address: yup
    .string()
    .trim()
    .required('This field is required'),
  phone_number: yup
    .string()
    .required('This field is required')
    .matches(/^\S*$/, 'Phone number cannot contain spaces')
});

const PlanningMode = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);
  const [placePredictions, setPlacePredictions] = useState([]);
  const isLoadingQuestion = useSelector(askPlanningModeLoading);
  const history = useHistory();
  const locationUA = history.location.hash === '#ua';
  const phoneNumberCode = locationUA ? '+38.' : '+1.';
  const refAutocomplete = useRef(null);
  const autocompleteService = useRef(null);
  const dispatch = useDispatch();
  const [isFocused, setIsFocused] = useState(false);

  const isNotMobile = useMediaQuery(device.TABLET);

  useScrollPosition();

  const resolver = useYupValidationResolver(validationSchema);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { isValid, errors },
    setValue,
    getValues,
    reset,
    trigger
  } = useForm({
    resolver,
    defaultValues: {
      phone_number: '',
      address: ''
    },
    reValidateMode: 'onBlur'
  });
  const watchedFields = watch('address');

  useEffect(() => {
    const loadPlace = async () => {
      try {
        if (window?.google) {
          await window.google.maps.importLibrary('places');
          autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
      } catch (e) {
        console.log(e);
      }
    };
    loadPlace();
  }, []);

  useEffect(() => {
    if (!isSelecting) {
      handleFindAddress(watchedFields);
    } else {
      setIsSelecting(false);
    }
  }, [watchedFields]);

  const submitAskUsForm = event => {
    mixpanel.track('Ask Planning Mode Form Submit', {
      'Entry Point': analyticsPageTypes.PLANNING_MODE,
      $phone_number: `${phoneNumberCode}${event.phone_number}`
    });

    window.grecaptcha
      .execute(CAPTCHA_SITE_KEY, {
        action: captchaActions.ASK_US_ANYTHING.SUBMIT
      })
      .then(token => {
        const data = {
          phone_number:
            `${phoneNumberCode}${event.phone_number}`?.replace(/\./g, '') || '',
          address: event.address,
          content_type: 'suggest'
        };
        dispatch(
          askPlanningMode({
            data: { entryPoint: analyticsPageTypes.PLANNING_MODE, ...data },
            security_token: token,
            isAtticPage: true,
            onCloseAction: reset,
            ...data
          })
        );
      });
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (
      refAutocomplete.current &&
      !refAutocomplete.current.contains(event.target)
    ) {
      setDropdownVisible(false);
    }
  };

  const handleFindAddress = async value => {
    try {
      if (value?.length > 1) {
        autocompleteService.current.getPlacePredictions(
          {
            input: value,
            types: ['address'],
            componentRestrictions: {
              country: 'us'
            }
          },
          (predictions, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              predictions?.length > 0
            ) {
              setPlacePredictions(predictions);
              setDropdownVisible(true);
            } else {
              setPlacePredictions([]);
              setDropdownVisible(false);
            }
          }
        );
      } else {
        setPlacePredictions([]);
        setDropdownVisible(false);
      }
    } catch (e) {
      console.log('error ==>>', e);
    }
  };

  const handleClickAutocompleteItem = item => {
    setIsSelecting(true);
    setValue('address', item?.description);
    trigger('address');
    setDropdownVisible(false);
  };

  const handleCloseMobileAutocomplete = () => {
    setIsFocused(false);
  };

  const handleMobileAutocompleteChange = e => {
    setValue('address', e.target.value);
  };
  const handleClearAddressInputValue = e => {
    setValue('address', '');
  };

  const handleClickAutocompleteMobileItem = item => {
    setIsFocused(false);
    handleClickAutocompleteItem(item);
  };

  return (
    <div>
      {isFocused && !isNotMobile && (
        <MobileAutocomplete
          onChange={handleMobileAutocompleteChange}
          onClose={handleCloseMobileAutocomplete}
          value={getValues('address')}
          onClearInputValue={handleClearAddressInputValue}
          items={placePredictions}
          onClickAutocompleteItem={handleClickAutocompleteMobileItem}
        />
      )}
      <ScreenContentWrapper>
        <Wrapper id="formSection" withHeader>
          <HeaderWrapper>
            <HeaderTitle>
              Introducing <ColoredHeader>Planning Mode</ColoredHeader>
            </HeaderTitle>
            <HeaderText>
              Enter your home address and we will use AI to generate a
              customized report for your home: regular upkeep needs, maintenance
              recommendations, renovation ideas.
            </HeaderText>
          </HeaderWrapper>
          <FormWrapper>
            <InputWrapper>
              <StyledInput
                onFocus={() => {
                  setIsFocused(true);
                }}
                autoComplete="off"
                fullWidth
                errorBorders={errors.address}
                type="text"
                placeholder="Start typing your home address"
                {...register('address')}
              />
              {dropdownVisible && placePredictions?.length > 0 && (
                <AutocompleteList ref={refAutocomplete}>
                  {placePredictions.map((item, index) => {
                    return (
                      <div key={index}>
                        <AutocompleteListItem
                          onClick={() => handleClickAutocompleteItem(item)}>
                          <p>{item?.description}</p>
                        </AutocompleteListItem>
                        {index < placePredictions.length - 1 && <Spacer />}
                      </div>
                    );
                  })}
                </AutocompleteList>
              )}
            </InputWrapper>

            <InputGroup>
              <InputPhoneWrapper leftMargin={locationUA ? 46 : 38}>
                <InputPrefix color={'#FFFFFF'} left={12}>
                  {phoneNumberCode}
                </InputPrefix>
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error }
                  }) => {
                    return (
                      <NumberFormat
                        type="tel"
                        id="phoneNumber"
                        format="###.###.####"
                        customInput={Input}
                        placeholder="202.460.7470"
                        padding="0 48px 0 72px"
                        smallBottomMargin
                        lineHeight={1}
                        errorBorders={error}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        name={name}
                        ref={ref}
                      />
                    );
                  }}
                  name={'phone_number'}
                />
              </InputPhoneWrapper>
              <SendButtonWrapper>
                <Button
                  title={'Get Report'}
                  loading={isLoadingQuestion}
                  disabled={!isValid}
                  onClick={handleSubmit(submitAskUsForm)}
                />
              </SendButtonWrapper>
            </InputGroup>
          </FormWrapper>
        </Wrapper>
        <ImageBackground
          src={checkThePriceBackground}
          alt="backgroundCheckThePrice"
        />
        {isLoadingQuestion && (
          <LoaderScreen isVisible={isLoadingQuestion}>
            <WrapperCenter>
              <IconLoader src={iconLoader} />
              <p>we’re running your customized report…</p>
            </WrapperCenter>
            <BackgroundOpacity />
          </LoaderScreen>
        )}
      </ScreenContentWrapper>
    </div>
  );
};

export default PlanningMode;
