import actionTypes from 'common/enums/actionTypes';

export const getArticles = payload => ({
  type: actionTypes.GET_ARTICLES_FETCH,
  payload
});

export const getArticle = payload => ({
  type: actionTypes.GET_ARTICLE_FETCH,
  payload
});
