import React, { useEffect } from 'react';
import { closeModal } from 'store/actions/modal';
import { analyticsPageTypes } from 'common/enums/pages';
import { GetApp } from 'components';
import {
  ModalShadow,
  ModalContent,
  CloseButton,
  CloseButtonIcon,
  MainContent,
  TopHeader,
  Heading,
  ButtonWrapper,
  ScrollableContent,
  ScrollableHeader,
  DownloadContainer,
  Download,
  MobileDownload,
  QRcode,
  DesktopView,
  TextSection,
  Title,
  Description
} from './styled';
import { Text, Button } from 'components';
import closeImage from 'static/images/icons/close.svg';
import { connect } from 'react-redux';
import { getFirebaseDobbyDownloadLink } from 'common/helpers';
import { useLocation } from 'react-router-dom';
import { useUtmParams } from 'hooks';
import homeQR from 'static/images/qr/home.webp';

const Modal = ({
  children,
  visible,
  title,
  text,
  topPosition = false,
  borderRadius = 10,
  headingPaddingBottom,
  header = true,
  closeButton,
  centeredContent = false,
  lightBackground = true,
  width,
  padding,
  margin,
  closeModal,
  onModalClose,
  buttonText,
  onClose,
  contentDisplay,
  scrollable,
  mobilePadding,
  showDownload,
  aiResult
}) => {
  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
    if (onModalClose) {
      onModalClose();
    } else {
      closeModal();
    }
  };
  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, []);
  const location = useLocation();

  const { utmParamsString } = useUtmParams({
    page: analyticsPageTypes.ATTIC
  });
  const dobbyAppDownloadLink = getFirebaseDobbyDownloadLink({
    utmParamsString,
    pathname: location.pathname
  });
  return (
    <ModalShadow
      visible={visible}
      onClick={onModalClose}
      lightBackground={lightBackground}
      topPosition={topPosition}
      aiResult={aiResult}>
      <ModalContent
        onClick={e => e.stopPropagation()}
        aiResult={aiResult}
        width={width}
        padding={padding}
        topPosition={topPosition}
        margin={margin}
        borderRadius={borderRadius}
        contentDisplay={contentDisplay}
        mobilePadding={mobilePadding}
        scrollable={scrollable}>
        {scrollable && (
          <ScrollableHeader>
            <div />
          </ScrollableHeader>
        )}
        <ScrollableContent aiResult={aiResult} scrollable={scrollable}>
          {header && (
            <TopHeader
              centeredContent={centeredContent}
              headingPaddingBottom={headingPaddingBottom}>
              {Boolean(title) && (
                <Heading centeredContent={centeredContent}>{title}</Heading>
              )}
              {Boolean(text) && (
                <Text description2 centeredContent={centeredContent}>
                  {text}
                </Text>
              )}
            </TopHeader>
          )}
          <MainContent centeredContent={centeredContent}>
            {children}
          </MainContent>
          {buttonText && (
            <ButtonWrapper>
              <Button title={buttonText} onClick={handleCloseModal} />
            </ButtonWrapper>
          )}

          {closeButton && (
            <CloseButton
              centeredContent={centeredContent}
              onClick={handleCloseModal}>
              <CloseButtonIcon src={closeImage} />
            </CloseButton>
          )}
          {/* add here*/}
          {showDownload && (
            <DownloadContainer aiResult={aiResult}>
              <DesktopView>
                <TextSection>
                  <Title>Open the App</Title>
                  <Description>
                    For estimates, customized property report, and getting your
                    job done with 5-star guarantee
                  </Description>
                </TextSection>
                <QRcode
                  src={homeQR}
                  alt="Scan QR code to download the Dobby home services app for estimates, property reports, and a 5-star service guarantee."
                />
              </DesktopView>
              <MobileDownload>
                <GetApp
                  entryPoint={analyticsPageTypes.CHECK_THE_PRICE}
                  modal={true}
                />
              </MobileDownload>
            </DownloadContainer>
          )}
        </ScrollableContent>
      </ModalContent>
    </ModalShadow>
  );
};

Modal.defaultProps = {
  visible: false,
  closeButton: true
};

const mapDispatchToProps = {
  closeModal
};

export default connect(null, mapDispatchToProps)(Modal);
