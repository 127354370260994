import quoteImage from 'static/images/quotes/quote-image-heidi.webp';

export const imageQuoteContent = {
  IMAGE: quoteImage,
  TEXT:
    'As a busy business owner and parent, Dobby has become essential in the running of my house. From improvement projects to emergencies, they have you covered with communicative and respectful vetted professionals to tackle whatever has been lingering on the to-do list. No more back and forth with contractors or unreturned calls!',
  NAME: 'Heidi'
};

export const reviewsSection = {
  TITLE: 'An experience like nothing else'
};

export const plans = ['Annually', 'Monthly', 'Pay-as-you-go'];

export const items = [
  'Carpet cleaning',
  'Chimney and fireplace cleaning',
  'Duct cleaning',
  'Garage door maintenance',
  'Gutter cleaning',
  'Handyman',
  'Home inspection',
  'Home cleaning',
  'HVAC tune-up',
  'Landscaping',
  'Pest control',
  'Plumbing inspection',
  'Radon testing',
  'Roof inspection',
  'Window washing',
  'Yard cleaning'
];
