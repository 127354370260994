import maintainanceImg from 'static/images/icons/category-maintainance.svg';
import inspectionsImg from 'static/images/icons/category-inspections.svg';
import specialtyImg from 'static/images/icons/category-specialty.svg';
import electricalImg from 'static/images/icons/category-electrical.svg';
import plumbingImg from 'static/images/icons/category-plumbing.svg';
import landscapingImg from 'static/images/icons/category-landscaping.svg';
import hvacImg from 'static/images/icons/category-hvac.svg';

export const items = {
  GENERAL: {
    TYPE: 'general',
    IMAGE: maintainanceImg,
    TITLE: 'General maintenance',
    ORDER: 1,
    LIST: [
      'Drywall repairs',
      'Wood repair',
      'Caulking & sealing',
      'Interior room painting',
      'Trim painting',
      'Exterior painting',
      'Window repairs/replacement',
      'Hanging pictures/mounting TVs',
      'Junk hauling',
      'Pressure washing',
      'Cabinet installation & repair',
      'Towel bar & hook installation',
      'Appliance repair and replacement',
      'Roof leaks and replacement',
      'Cabinet & furniture painting',
      'Window Washing',
      'Carpet cleaning',
      'Gutter cleaning',
      'Pest control',
      'Wallpaper installation',
      'Backsplash/tiling installation',
      'Furniture assembly',
      'Countertop installation',
      'Powerwashing ',
      'Crown molding installation'
    ],
    ALT: 'Dobby home services app includes general maintenance services.'
  },
  SAFETY: {
    TYPE: 'safety',
    IMAGE: inspectionsImg,
    ORDER: 6,
    TITLE: 'Renovations & Upgrades',
    IS_HIDE_HOME: true,
    LIST: [
      'Kitchen Renovation',
      'Bathroom Remodel',
      'Flooring Upgrades',
      'Deck and Patio Installation',
      'Roof Replacement',
      'Basement Finishing',
      'Attic Conversion',
      'Tilework',
      'Garage Renovation',
      'Exterior Painting and Siding',
      'Retaining wall',
      'Insulation'
    ],
    ALT: 'Dobby home services app includes safety services.'
  },
  SPECIALITY: {
    TYPE: 'specialty',
    IMAGE: specialtyImg,
    TITLE: 'Specialty',
    ORDER: 2,
    LIST: [
      'Alarm system work',
      'Waterproofing',
      'Sprinkler system maintenance',
      'Chimney cleaning',
      'Concrete foundation repair',
      'Tuck pointing and masonry repair',
      'Mold issues',
      'Water remediation',
      'Marble and stone refinishing',
      'Floor installation',
      'Sanding & staining flooring',
      'Garage/overhead door maintenance & repair'
    ],
    ALT: 'Dobby home services app includes specialty services.'
  },
  ELECTRICAL: {
    TYPE: 'electrical',
    IMAGE: electricalImg,
    ORDER: 4,
    TITLE: 'Electrical',
    LIST: [
      'Outlet installation',
      'Ceiling & bath fan repair',
      'Light switch installation',
      'Smart security camera installation',
      'Smart thermostat installation',
      'Electric panel upgrades and replacement',
      'Pendant/Chandelier installation',
      'Electric car charger installation',
      'Light fixture upgrades'
    ],
    ALT: 'Dobby home services app includes electrical services.'
  },
  PLUMBING: {
    TYPE: 'plumbing',
    IMAGE: plumbingImg,
    ORDER: 3,
    TITLE: 'Plumbing',
    LIST: [
      'Toilet replacements',
      'Leaking faucet repair',
      'Fixture installation',
      'Drain repair',
      'Pipe replacements',
      'Sump pump work',
      'Water heater replacement',
      'Gas piping work'
    ],
    ALT: 'Dobby home services app includes plumbing services.'
  },
  LANDSCAPING: {
    TYPE: 'landscaping',
    IMAGE: landscapingImg,
    ORDER: 5,
    TITLE: 'Landscaping + Hardscaping',
    LIST: [
      'Lawn mowing',
      'Tree and bush trimming',
      'Weeding',
      'Leaf blowing',
      'Arborist services',
      'Deck and fence repair',
      'Patio and pathway installation',
      'Mulching'
    ],
    ALT:
      'Dobby home services app includes landscaping and hardscaping services.'
  },
  HVAC: {
    TYPE: 'HVAC',
    IMAGE: hvacImg,
    ORDER: 7,
    TITLE: 'HVAC',
    LIST: [
      'Duct cleaning',
      'Central A/C repair',
      'Furnace or boiler repair',
      'Compressor replacement',
      'Routine service',
      'Filter replacement',
      'System replacement',
      'Smart thermostat installation'
    ],
    ALT: 'Dobby home services app includes HVAC services.'
  }
};
