export const buttonTypes = {
  START_JOB: 0,
  SUPPORT: 1
};

export const howItWorks = {
  STEP_1: {
    TITLE: `Start a new job to share the details of your project`,
    MAX_WIDTH: 565,
    TEXT:
      'Whether you need a pre-sale inspection, handyman, or renovation expert, everything starts here. Small jobs, large jobs, we manage all jobs for your home.',
    IMAGE: require('static/images/backgrounds/get-started-1.webp')
  },
  STEP_2: {
    TITLE: 'That’s it!\nWe take it from there',
    TEXT: `We’ll connect you with one of our pre-vetted service providers, handpicked for your job. From upfront pricing to scheduling, you’ll chat with your pro in‑app and have Dobby support to guide everything along.`,
    MAX_WIDTH: 673,
    IMAGE: require('static/images/backgrounds/get-started-2.webp')
  },
  STEP_3: {
    TITLE: 'Pay in-app when the work is complete',
    TEXT:
      "We stand by quality work. When your job is done, you'll approve your invoice and pay easily through the app. And we offer financing to make that dream kitchen a reality even sooner!",
    MAX_WIDTH: 673,
    IMAGE: require('static/images/backgrounds/get-started-3.webp')
  },
  STEP_4: {
    TITLE: 'Talk to us when you need anything',
    TEXT:
      'You’ve got a real support team always available to help, just a click away.',
    IMAGE: require('static/images/backgrounds/get-started-4.webp'),
    MAX_WIDTH: 673,
    BUTTON: {
      TITLE: 'Start a job',
      TYPE: buttonTypes.START_JOB
    }
  }
};
