import React from 'react';
import PropTypes from 'prop-types';

import { BackgroundImageHolder, Image, ImagePlaceholder } from './styled';
import { pageTypes } from 'common/enums/pages';

const BackgroundImage = ({
  src1x,
  src2x,
  placeholderSrc,
  mobileBackground,
  type,
  alt
}) => {
  return (
    <BackgroundImageHolder>
      <Image
        type={type}
        src={
          window.innerWidth >= 1024
            ? src2x
              ? src2x
              : src1x
            : mobileBackground
            ? mobileBackground
            : src1x
        }
        alt={alt}
      />
      <ImagePlaceholder
        type={type}
        src={
          window.innerWidth < 1024 && mobileBackground
            ? mobileBackground
            : placeholderSrc
        }
      />
    </BackgroundImageHolder>
  );
};

BackgroundImage.propTypes = {
  type: PropTypes.oneOf([
    'about-us',
    'press-release',
    'become-a-partner',
    'love-our-planet',
    ...Object.values(pageTypes)
  ]),
  src: PropTypes.string
};

export default BackgroundImage;
