import React, { useEffect, useState } from 'react';
import message from 'static/images/common/message.webp';
import reviewsDownload from 'static/images/common/phones-mobile.webp';
import phones from 'static/images/common/phones.webp';
import mobileRoad from 'static/images/common/mobile-road.webp';
import road from 'static/images/common/road.webp';
import { Amplify } from 'aws-amplify';
import {
  ImageWrapper,
  Wrapper,
  Container,
  MainSection,
  Title,
  ColoredText,
  SubMessageText,
  HowItWorks,
  Message,
  MessageText,
  ButtonWrapper,
  Header,
  Phones,
  Customize,
  TableHeader,
  TableItem,
  FooterButton,
  Table,
  Road,
  MobileRoad,
  ReviewTitle,
  Footer,
  FooterText,
  MobileImage,
  DesktopImage,
  FooterContent,
  TableText,
  DobbyCareText,
  Phone,
  PhoneSection,
  PhoneContainer,
  PhoneCountry,
  WorksTitle
} from './styled';
import { openModal } from 'store/actions/modal';
import { signIn } from 'store/actions/auth';
import { setSubscriptionStep } from 'store/actions/subscription';

import { APP_ENV } from 'common/constants/app';
import { appEnvs } from 'common/enums/app';
import { amplifyConfigCustomer } from 'common/enums/config';
import { pageTypes } from 'common/enums/pages';
import { Reviews, Input, SquareButton } from 'components';
import { Rating } from './components';
import { items } from './enums';

import Confirm from './components/Stages/Confirm';
import NoUser from './components/Stages/NoUser';
import Final from './components/Stages/Final';
import { connect } from 'react-redux';
import {
  selectUser,
  selectSignInLoading,
  selectSubscriptionStep
} from 'store/reducers/subscription';
import NumberFormat from 'react-number-format';
import { ReactComponent as Checkbox } from 'static/images/icons/checkbox.svg';
import { subscriptionSteps } from 'common/enums/subscription';
import { useHistory } from 'react-router-dom';
import { reviews_2 as reviews } from 'common/enums/reviews';

const DobbyCare = ({ signIn, signInLoading, step }) => {
  const [phone, setPhone] = useState('');
  const [validationActive, setValidationActive] = useState(false);
  useEffect(() => {
    Amplify.configure(
      APP_ENV === appEnvs.PRODUCT
        ? amplifyConfigCustomer.product
        : amplifyConfigCustomer.staging
    );
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);
  const history = useHistory();
  const isUA = history.location.hash === '#ua';
  const phoneNumberCode = isUA ? '+38.' : '+1.';
  if (step === subscriptionSteps.FINAL) {
    return (
      <Final
        onClose={() => {
          setValidationActive(false);
          setPhone('');
        }}
      />
    );
  }
  if (step === subscriptionSteps.NO_USER) {
    return <NoUser />;
  }
  if (step === subscriptionSteps.CONFIRM) {
    return <Confirm />;
  }
  return (
    <Wrapper>
      <ImageWrapper>
        <img src={reviewsDownload} />
      </ImageWrapper>
      <MainSection>
        <Container>
          <Header>
            <div>
              <Title maxWidth="688px">
                Put your home on auto-pilot with{' '}
                <ColoredText>DobbyCare</ColoredText>
              </Title>
              <Message>
                <img src={message} />
                <MessageText>
                  “The Dobby team figured out what my home needs and crafted a
                  perfect package for me. I don't worry about upkeep at all
                  anymore.”
                </MessageText>
                <SubMessageText>A homeowner in DC area</SubMessageText>
              </Message>
            </div>
            <Phones>
              <img src={phones} />
            </Phones>
          </Header>
          <ButtonWrapper>
            <a href="#how-it-works">
              <SquareButton>Learn More</SquareButton>
            </a>
          </ButtonWrapper>
        </Container>
      </MainSection>
      <div id="how-it-works" />
      <HowItWorks>
        <Container maxWidth={1200}>
          <WorksTitle>How it works</WorksTitle>
          <MobileRoad>
            <img src={mobileRoad} />
          </MobileRoad>
          <Road>
            <img src={road} />
          </Road>
        </Container>
      </HowItWorks>
      <Customize>
        <Container>
          <Title textAlign="center">
            <ColoredText>DobbyCare</ColoredText> will handle for you
          </Title>

          <Table>
            <TableHeader>DobbyCare</TableHeader>
            {items.map((item, i) => (
              <TableItem even={i % 2 === 0} key={item}>
                <Checkbox />
                <span>{item}</span>
              </TableItem>
            ))}
          </Table>
          <TableText id="phone">
            ... and other jobs needed to keep your home healthy
          </TableText>
          <DobbyCareText>
            Let’s connect your DobbyCare plan with your Dobby account
          </DobbyCareText>
          <ButtonWrapper>
            <PhoneSection>
              <Phone>Enter phone number</Phone>

              <PhoneContainer>
                <NumberFormat
                  type="tel"
                  format="###.###.####"
                  customInput={Input}
                  placeholder="202.460.7470"
                  padding={`12px 30px 12px ${isUA ? 55 : 46}px`}
                  smallBottomMargin={true}
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  errorBorders={validationActive && phone.trim().length !== 12}
                  onBlur={() => setValidationActive(true)}
                />
                <PhoneCountry>{phoneNumberCode}</PhoneCountry>
              </PhoneContainer>
            </PhoneSection>

            <SquareButton
              disabled={phone.trim().length !== 12}
              loading={signInLoading}
              onClick={() => signIn({ phoneNumberCode, phone })}>
              Verify
            </SquareButton>
          </ButtonWrapper>
        </Container>
      </Customize>

      <ReviewTitle>You're in good hands</ReviewTitle>
      <Rating reviews={reviews} />
      <Reviews reviews={reviews} type={pageTypes.DOBBY_CARE} />
      <Footer>
        <Container>
          <FooterContent>
            <div>
              <MobileImage>
                <img src={phones} />
              </MobileImage>
              <FooterText>
                Subscribe to <ColoredText>DobbyCare</ColoredText> and we will
                get back to you soon
              </FooterText>
              <FooterButton>
                <a href="#phone">
                  <SquareButton>Subscribe</SquareButton>
                </a>
              </FooterButton>
            </div>
            <DesktopImage>
              <img src={phones} />
            </DesktopImage>
          </FooterContent>
        </Container>
      </Footer>
    </Wrapper>
  );
};
const mapStateToProps = state => ({
  user: selectUser(state),
  signInLoading: selectSignInLoading(state),
  step: selectSubscriptionStep(state)
});

const mapDispatchToProps = { signIn, openModal, setSubscriptionStep };

export default connect(mapStateToProps, mapDispatchToProps)(DobbyCare);
