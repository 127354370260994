import React from 'react';
import PropTypes from 'prop-types';
import { PaginationWrapper, PageNumber, Arrow, Dots } from './styled';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const getPages = () => {
    const pages = [];
    if (totalPages <= 1) return [1];

    pages.push(1);
    if (currentPage > 3) pages.push('...');
    for (
      let i = Math.max(2, currentPage);
      i <= Math.min(totalPages - 1, currentPage + 1);
      i++
    ) {
      pages.push(i);
    }
    if (currentPage < totalPages - 2) pages.push('...');
    pages.push(totalPages);

    return pages;
  };

  const pages = getPages();

  return (
    <PaginationWrapper>
      <Arrow
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}>
        &lt;
      </Arrow>
      {pages.map((page, index) =>
        page === '...' ? (
          <Dots key={index}>...</Dots>
        ) : (
          <PageNumber
            key={index}
            active={page === currentPage}
            disabled={page === currentPage}
            onClick={() => onPageChange(page)}>
            {page}
          </PageNumber>
        )
      )}
      <Arrow
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}>
        &gt;
      </Arrow>
    </PaginationWrapper>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
