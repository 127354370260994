import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import { Helmet } from 'react-helmet-async';
import { XMLParser } from 'fast-xml-parser';
import QRCode from 'react-qr-code';
import mixpanel from 'mixpanel-browser';
import { isDesktop } from 'react-device-detect';

import {
  Wrapper,
  Title,
  ArticleImage,
  SectionDesc,
  Image,
  LoaderWrapper,
  MainDesc,
  DownloadImageWrapper,
  MediaWrapper,
  DownloadButton,
  CenterWrapper,
  QRWrapper,
  Caption
} from './styled';

import { analyticsPageTypes } from 'common/enums/pages';
import { DownloadAppImageText } from 'components';
import { getArticle } from 'store/actions/article';
import { selectArticle, selectLoadingArticle } from 'store/reducers/article';
import { connect } from 'react-redux';
import { LoadingSpinner } from '@sourcegraph/react-loading-spinner';
import NotFound from 'pages/NotFound';
import { useTrackPageViews, useUtmParams } from 'hooks';
import styles from 'common/enums/styles';
import { getFirebaseDobbyDownloadLink } from 'common/helpers';
import links from 'common/constants/links';

const DownloadBtn = ({ entryPoint, btnName, onClick, utmLink }) => {
  const isAndroid = navigator.userAgent.includes('Android');
  const { utmParamsString } = useUtmParams({
    page: Boolean(utmLink) ? `${entryPoint}_${utmLink}` : entryPoint,
    utm_source: 'web_articles',
    isDesktop: isDesktop
  });
  const dobbyAppDownloadLinkIOS = getFirebaseDobbyDownloadLink({
    utmParamsString,
    pathname: window.location.pathname,
    desktopFallbackLink: links.MARKET_HO_APP_IOS
  });
  const dobbyAppDownloadLinkAndroid = getFirebaseDobbyDownloadLink({
    utmParamsString,
    pathname: window.location.pathname,
    desktopFallbackLink: links.MARKET_HO_APP_ANDROID
  });

  return (
    <CenterWrapper>
      <QRWrapper>
        <QRCode
          value={
            isAndroid ? dobbyAppDownloadLinkAndroid : dobbyAppDownloadLinkIOS
          }
          title="Scan QR code to download the Dobby home services app for estimates, property reports, and a 5-star service guarantee."
          fgColor={styles.colors.WHITE}
          bgColor={styles.colors.PURPLE}
          level="L"
          size={136}
        />
      </QRWrapper>
      <DownloadButton
        href={isAndroid ? dobbyAppDownloadLinkAndroid : dobbyAppDownloadLinkIOS}
        onClick={onClick}>
        {btnName || 'Download Dobby'}
      </DownloadButton>
    </CenterWrapper>
  );
};

const Article = ({ article, getArticle, isLoading }) => {
  const { slug = '' } = useParams();
  const title = article?.Title;
  const summary = article?.Summary;
  const keywords = article?.Keywords;
  const content = article?.Content;
  const banner = article?.Banner?.data?.attributes?.url;
  const articleBody = article?.Body;
  // const entryPoint = `${slug}`;
  const parser = new XMLParser();
  const isAndroid = navigator.userAgent.includes('Android');

  useTrackPageViews({ page: `Article_${title}` });

  const handleLinkClick = (name, href) => {
    mixpanel.track(`Link Click - Article_${title}`, {
      'Page Name': `Article_${title}`,
      'Field Source': name,
      'Link Source': href,
      Slug: slug,
      'Link Clicked': new Date().toISOString(),
      'Is interacted': true
    });
  };

  useEffect(() => {
    const sessionId =
      Date.now().toString(36) + Math.random().toString(36)[(2, 9)];

    if (article) {
      mixpanel.track(`Session Start - Article_${title}`, {
        sessionId,
        Title: title,
        Slug: slug,
        'Session Started': new Date().toISOString()
      });
    }

    return () => {
      if (article) {
        mixpanel.track(`Session End - Article_${title}`, {
          sessionId,
          Title: title,
          Slug: slug,
          'Session Ended': new Date().toISOString()
        });
      }
    };
  }, [article]);

  useEffect(() => {
    getArticle({ slug });
    window.scrollTo({ top: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <LoadingSpinner />
      </LoaderWrapper>
    );
  }

  if (!isLoading && !article) {
    return <NotFound />;
  }

  return (
    <Wrapper>
      <Helmet>
        <meta name="description" content={summary} />
        <meta name="keywords" content={keywords} />
        <meta name="title" content={title} />
        <meta name="author" content="Dobby" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={summary} />
        <meta property="og:image" content={banner} />
        <meta
          property="og:url"
          content={`https://www.hellodobby.com/articles/${slug}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Dobby" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:creator" content={'Dobby'} />
        <meta name="twitter:card" content={'article'} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={summary} />
      </Helmet>
      {banner && (
        <Image>
          <img src={banner} alt="Content" />
        </Image>
      )}
      <Title>{title}</Title>
      <MainDesc id="mainSection">
        <SectionDesc
          children={articleBody}
          rehypePlugins={[rehypeRaw]}
          components={{
            pre: ({ node, ...props }) => {
              const firstChild = props.children[0].props.children[0];
              if (firstChild.includes('cta')) {
                const jsonObj = parser.parse(firstChild);
                const cta = jsonObj.cta || {};
                return (
                  <DownloadImageWrapper>
                    <DownloadAppImageText
                      heading={cta.heading}
                      desc={cta.description}
                      image={cta.image}
                      btnTitle={cta.button}
                      entryPoint={slug}
                      articleSource
                    />
                  </DownloadImageWrapper>
                );
              } else if (firstChild.includes('download')) {
                const jsonObj = parser.parse(firstChild);
                const downloadButton = jsonObj.download || {};

                return (
                  <DownloadBtn
                    entryPoint={slug}
                    btnName={downloadButton.title}
                    utmLink={downloadButton.utmlink}
                    onClick={() =>
                      handleLinkClick(
                        downloadButton.title || 'Download Dobby',
                        `${
                          isAndroid
                            ? links.MARKET_HO_APP_ANDROID
                            : links.MARKET_HO_APP_IOS
                        }utm_source=web_articles&utm_medium=${slug}${Boolean(
                          downloadButton.utmlink
                            ? `_${downloadButton.utmlink}`
                            : ''
                        )}`
                      )
                    }
                  />
                );
              } else if (firstChild.includes('mediacaption')) {
                const jsonObj = parser.parse(firstChild);
                const mediaCaption = jsonObj.mediacaption || {};
                return (
                  <Caption embedded>
                    {mediaCaption}
                    <br />
                    ***
                  </Caption>
                );
              }
              return null;
            },
            oembed: ({ node, ...props }) => (
              <MediaWrapper>
                <iframe
                  src={`${props.url}?controls=0&fs=0&modestbranding=1&showinfo=0&rel=0`}
                  style={{ border: 0 }}
                />
              </MediaWrapper>
            ),
            img: ({ node, ...props }) => (
              <MediaWrapper>
                <ArticleImage src={props.src} alt={props.alt} />
              </MediaWrapper>
            ),
            figure: ({ node, ...props }) => {
              if (props.className == 'image') {
                return (
                  <MediaWrapper>
                    <figure>
                      <img
                        src={props.children[0]?.props?.src}
                        alt={props.children[0]?.props?.alt}
                      />
                      {props.children[1]?.props?.children?.[0] && (
                        <Caption>
                          {props.children[1]?.props?.children?.[0]}
                          <br />
                          ***
                        </Caption>
                      )}
                    </figure>
                  </MediaWrapper>
                );
              } else if (props.className == 'media') {
                return (
                  <MediaWrapper>
                    <iframe
                      src={`${props.children[0]?.props?.url}?controls=0&fs=0&modestbranding=1&showinfo=0&rel=0`}
                      style={{ border: 0 }}
                    />
                  </MediaWrapper>
                );
              }
              return null;
            },
            a: ({ node, ...props }) => (
              <a
                href={props.href}
                onClick={() => handleLinkClick(props.children?.[0], props.href)}
                {...props}>
                {props.children?.[0]}
              </a>
            )
          }}
        />
      </MainDesc>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  isLoading: selectLoadingArticle(state),
  article: selectArticle(state)
});

const mapDispatchToProps = {
  getArticle
};

export default connect(mapStateToProps, mapDispatchToProps)(Article);
