import umbrellaImage from 'static/images/icons/umbrella.svg';
import medalImage from 'static/images/icons/medal.svg';
import peopleImage from 'static/images/icons/people.svg';
import quoteImage from 'static/images/quotes/quote-image-alex.webp';

export const categoriesSection = {
  TITLE: 'Large jobs. Small jobs. All jobs.',
  SUBTITLE:
    "From small repairs to regular maintenance to dream upgrades, we've got you covered. Say goodbye to that leaky faucet and 90s bathroom. We will get it done with top quality service pros and a fair price. And we will be there along the way, just a click away!"
};

export const items = {
  WORK: {
    TYPE: 0,
    IMAGE: medalImage,
    ALT:
      'Get the best home service providers near you, verified and trusted contractors for home improvement',
    TITLE: 'Get the best pros',
    TEXT:
      'From highest quality craftsmanship\nto fair pricing - our pre-vetted,\ninvite-only service pros are the best'
  },
  WEEKENDS: {
    TYPE: 1,
    IMAGE: umbrellaImage,
    ALT:
      'All-in-one home service management, from maintenance to renovations, handled by experts.',
    TITLE: 'Let us manage it all',
    TEXT:
      'We manage the job on your behalf and quarterback everything from the start to finish, so no more back and forth'
  },
  PEOPLE: {
    TYPE: 2,
    IMAGE: peopleImage,
    ALT:
      'Sustainable home improvement and renovation services for a better future.',
    TITLE: 'Build a better future',
    TEXT:
      "We're the only home services company built around local pros and the only one with a climate program"
  }
  // a click
};

export const imageQuoteContent = {
  IMAGE: quoteImage,
  TITLE: 'For homeowners,\nby homeowners.',
  TEXT:
    "We know what it's like to buy a home and see the to do list growing faster than you can cross it off. We've been there. After trying the other options out there, we knew there had to be a better way. That's why we built Dobby.",
  NAME: 'Alexandra Fontova',
  POSITION: 'co-founder & COO',
  ALT: 'Alexandra Fontova, co-founder and COO of Dobby home services.'
};
