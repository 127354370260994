import styled from 'styled-components/macro';
import styles from 'common/enums/styles';
import { device } from 'common/enums/devices';
import poster from 'static/images/backgrounds/landing-video-poster.webp';

export const Container = styled.div`
  color: ${styles.colors.DARK_BLUE};
  display: grid;
  justify-content: center;
  justify-items: center;
  padding: 0 31px;
  @media screen and ${device.LAPTOP} {
    gap: 0;
    margin: auto;
    max-width: 1440px;
    width: 100%;
    padding-top: 0;
    padding: 0;
    max-width: 838px;
    width: 100%;
    text-align: center;
  }
  @media screen and (min-width: 1260px) {
    gap: 0;
  }
`;

export const Wrapper = styled.section`
  position: relative;
  background: ${styles.colors.WHITE_NEW};
  @media screen and ${device.LAPTOP} {
    padding: 80px;
    padding-bottom: 0;
  }
`;
export const Title = styled.h1`
  margin: 0;
  letter-spacing: 0;
  color: ${styles.colors.YELLOW};
`;

export const TextContainer = styled.div`
  text-align: center;
  @media screen and ${device.LAPTOP} {
    max-width: 838px;
    width: 100%;
  }
`;

export const VideoHolder = styled.div`
  position: relative;
  display: grid;
  align-self: baseline;
  margin: auto;
  margin-top: 20px;
  width: 100%;
  max-width: 613px;
  @media screen and (min-width: 600px) {
    height: 345px;
  }
  @media screen and ${device.LAPTOP} {
    transform: scale(1.25);
    margin-top: 0;
  }
`;
export const TextWrapper = styled.div`
  margin-top: 16px;
  padding-right: 6px;
  @media screen and ${device.LAPTOP} {
    margin-top: 17px;
    padding-right: 0;
  }
`;
export const GetAppWrapper = styled.div`
  display: grid;
  justify-content: center;
  margin-top: 31px;
`;

export const VideoWrapper = styled.div`
  position: relative;
  margin-top: 130px;
`;
export const Video = styled.iframe`
  width: 100%;
  height: 100%;
  pointer-events: none;
  filter: drop-shadow(${styles.colors.boxShadows});
  border: none;
`;
export const VideoPlay = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: url(${poster});
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and ${device.LAPTOP} {
    display: block;
  }
`;

export const MobilePlayerHolder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  @media screen and ${device.LAPTOP} {
    display: none;
  }
`;
