import React from 'react';
import {
  ContactsContainer,
  ContactItem,
  ContactPhoto,
  ContactFullName,
  ContactPosition,
  ContactEmail,
  Image,
  Link
} from './styled';

const Contacts = ({ contacts, page }) => {
  return (
    <ContactsContainer>
      {contacts.map((contact, idx) => (
        <ContactItem key={idx} page={page}>
          <ContactPhoto page={page}>
            <Image
              src={contact.photo}
              bordered
              alt={contact.alt || 'Contact Photo'}
            />
          </ContactPhoto>
          <ContactFullName page={page}>{contact.fullName}</ContactFullName>
          <ContactPosition page={page}>{contact.position}</ContactPosition>
          <ContactEmail>
            <Link
              href={`mailto:${contact.email}`}
              target="_blank"
              page={page}
              rel="noopener noreferrer">
              {contact.email}
            </Link>
          </ContactEmail>
          {Boolean(contact.phone) && (
            <ContactEmail>
              <Link
                defaultColor
                href={`tel:${contact.phone}`}
                target="_blank"
                rel="noopener noreferrer">
                {contact.phone}
              </Link>
            </ContactEmail>
          )}
        </ContactItem>
      ))}
    </ContactsContainer>
  );
};

export default Contacts;
