import React from 'react';
import {
  Content,
  Wrapper,
  TextWrapper,
  ImageWrapper,
  Image,
  Container,
  Title,
  TextName,
  TextPosition,
  WrapperNames
} from './styled';
import Img from 'static/images/rating/satisfaction.webp';

const ImageText = () => {
  return (
    <Wrapper>
      <Title>
        A home services company that doesn't look like a home services company
      </Title>
      <Container>
        <ImageWrapper>
          <Image
            src={Img}
            alt="Customer sentiment scale showing Dobby is the most loved home services company"
          />
        </ImageWrapper>
        <TextWrapper>
          <Content>
            The things we love, we share with others. In business, there is a
            way to measure that love and sharing. We call it NPS or net promoter
            score.
            <br />
            <br />
            We ask all our users:{' '}
            <i>
              "Will you recommend this product to your friends & families?"
            </i>{' '}
            We take their responses, do some math, and calculate an accumulated
            rating that falls between +100 and -100. A higher NPS score means
            that the product solves the problem and users are in love with the
            product. A lower NPS score means the product doesn't solve the
            problem and doesn't make users happy.
            <br />
            <br /> Dobby has an <strong>NPS of +80</strong>. That's better than
            every F500 company. Even better than Apple, Starbucks, Airbnb,
            Costco, Delta Airlines, Southwest, or USAA, some of the most loved
            products and brands out there.
            <br />
            <br /> Here is the best part of this story. As the property manager
            of your home, Dobby is enjoying the most loved status in the most
            hated industry in America: the home services industry. The average
            NPS of other home services companies is below -30 (usual suspects:
            Homeadvisor, Angi, Thumbtack). That's worse than the worst airlines
            or insurance companies. That's why Dobby's +80 is not just a
            dazzling number but a meaningful change in American homeownership.
            We're completely transforming the home services industry as we know
            it.
          </Content>
          <WrapperNames>
            <div>
              <TextName>Satadru Sengupta</TextName>
              <TextPosition>CEO @ Dobby</TextPosition>
            </div>
            <div>
              <TextName>Alex Fontova</TextName>
              <TextPosition>COO @ Dobby</TextPosition>
            </div>
          </WrapperNames>
        </TextWrapper>
      </Container>
    </Wrapper>
  );
};

export default ImageText;
