import { NUMBER_OF_JOBS } from 'common/constants/content';

import { reviews } from 'common/enums/reviews';
import { analyticsPageTypes } from 'common/enums/pages';

import brandonWalls from 'static/images/reviews-charlotte/brandon-walls.webp';
import chadCockerill from 'static/images/reviews-charlotte/chad-cockerill.webp';
import darryllHarmon from 'static/images/reviews-charlotte/darryll-harmon.webp';
import frankSmith from 'static/images/reviews-charlotte/frank-smith.webp';
import gutterTeamNicole from 'static/images/reviews-charlotte/gutter-team-nicole.webp';
import josephRobinson from 'static/images/reviews-charlotte/joseph-robinson.webp';
import mosheYakupov from 'static/images/reviews-charlotte/moshe-yakupov.webp';
import richardDavis from 'static/images/reviews-charlotte/richard-davis.webp';

export const categoriesSection = {
  TITLE: 'Large jobs. Small jobs. All jobs.',
  SUBTITLE:
    "From small repairs to regular maintenance to dream upgrades, we've got you covered. Say goodbye to that leaky faucet and 90s bathroom. We will get it done with top quality service pros and a fair price. And we will be there along the way, just a click away!"
};

export const reviewsSection = {
  TITLE: 'Happy. Homeowners. Forever.',
  SUBTITLE:
    "We've served over <strong>3000</strong> homeowners in two markets and <strong>98%</strong> of our jobs are rated <strong>five stars</strong>. In an industry full of distrust and poor quality, Dobby is an epic love story between our homeowners and our service pros."
};

const reviewsCharlotte = {
  REVIEW_1: {
    TYPE: 'review_1',
    TEXT:
      'Brandon was great! Timely, professional, and proficient on a number of home needs! I originally hired him just to fix a leaking pipe in my shower and install a bathroom vanity, but he let me know he was able to not only help with that, but also paint, hang up various items on my walls, and install a ceiling fan. I’ll definitely be calling him again for all my handy man and plumbing needs.',
    NAME: 'Brandon Walls',
    POSITION: 'Plumbing',
    CUSTOMER_NAME: 'Jaclyn Rock',
    CUSTOMER_CITY: 'Charlotte',
    PRO_IMAGE: brandonWalls,
    ALT: 'Brandon Walls'
  },
  REVIEW_3: {
    TYPE: 'review_3',
    TEXT:
      'Everything went well. I especially appreciated the communication throughout the whole process. They kept me updated on estimated arrival, what they were doing and finding the whole time. The work needed was well explained. I feel confident that it was done right. Would recommend.',
    NAME: 'Chad Cockerill',
    POSITION: 'Plumbing',
    CUSTOMER_NAME: 'Elizabeth Cassidy',
    CUSTOMER_CITY: 'Charlotte',
    PRO_IMAGE: chadCockerill,
    ALT: 'Chad Cockerill'
  },
  REVIEW_4: {
    TYPE: 'review_4',
    TEXT:
      'Mr. Harmon was a pleasure to work with. He showed up when he said he would. He was neat, considerate of my space, and very professional. I feel comfortable requesting his services again for bigger more complex jobs.',
    NAME: 'Darryll Harmon',
    POSITION: 'Handyman Services',
    CUSTOMER_NAME: 'Tara Peace',
    CUSTOMER_CITY: 'Charlotte',
    PRO_IMAGE: darryllHarmon,
    ALT: 'Darryll Harmon'
  },
  REVIEW_6: {
    TYPE: 'review_6',
    TEXT:
      'Frank was lovely. Extremely personable and diligent. He covered all the nooks and crannies and took care of our gnat and pest problem!',
    NAME: 'Frank Smith',
    POSITION: 'Pest & Mold',
    CUSTOMER_NAME: 'Chase Hoffstadt',
    CUSTOMER_CITY: 'Charlotte',
    PRO_IMAGE: frankSmith,
    ALT: 'Frank Smith'
  },
  REVIEW_7: {
    TYPE: 'review_7',
    TEXT:
      'Joe was very easy to work with. Honest and patient. Great handyman work! We highly recommend.',
    NAME: 'Joseph Robinson',
    POSITION: 'Handyman Services',
    CUSTOMER_NAME: 'Vijay',
    CUSTOMER_CITY: 'Charlotte',
    PRO_IMAGE: josephRobinson,
    ALT: 'Joseph Robinson'
  },
  REVIEW_8: {
    TYPE: 'review_8',
    TEXT:
      'GREAT!!!! Very professional and knowledgeable. Mo quickly identified the issue, got the parts and completed the job. Mo was also very patient to answer all questions I had. 10/10!!!!',
    NAME: 'Moshe Yakupov',
    POSITION: 'Electrical',
    CUSTOMER_NAME: 'G Silva',
    CUSTOMER_CITY: 'Charlotte',
    PRO_IMAGE: mosheYakupov,
    ALT: 'Moshe Yakupov'
  },
  REVIEW_9: {
    TYPE: 'review_9',
    TEXT:
      'Richard did good job and support from Dobby is very nice. Overall had very good experience using Dobby.',
    NAME: 'Richard Davis',
    POSITION: 'Handyman Services',
    CUSTOMER_NAME: 'Murali Rebbapragada',
    CUSTOMER_CITY: 'Concord',
    PRO_IMAGE: richardDavis,
    ALT: 'Richard Davis'
  },
  REVIEW_5: {
    TYPE: 'review_5',
    TEXT:
      'Darryll was great! He got the job done quickly and efficiently. He was friendly and cleaned up after himself. I got exactly what I wanted',
    NAME: 'Darryll Harmon',
    POSITION: 'Handyman Services',
    CUSTOMER_NAME: 'Lori Seiple',
    CUSTOMER_CITY: 'Charlotte',
    PRO_IMAGE: darryllHarmon,
    ALT: 'Darryll Harmon'
  },
  REVIEW_10: {
    TYPE: 'review_2',
    TEXT:
      'They were very efficient and communicated very well with me throughout the process.',
    NAME: 'The Gutter Team',
    POSITION: 'Gutter Cleaning',
    CUSTOMER_NAME: 'Leo Velasquez',
    CUSTOMER_CITY: 'Charlotte',
    PRO_IMAGE: gutterTeamNicole,
    ALT: 'The Gutter Team'
  }
};

export const items = {
  JOIN_US_DMV: {
    entryPoint: analyticsPageTypes.JOIN_US_DMV,
    eventNameEntryPoint: 'DMV',
    reviews: reviews
  },
  JOIN_US_CHARLOTTE: {
    entryPoint: analyticsPageTypes.JOIN_US_CHARLOTTE,
    eventNameEntryPoint: 'CLT',
    reviews: reviewsCharlotte
  }
};

export const accordionItems = {
  name: 'Join us',
  qa: [
    {
      question: 'Why should I trust your Pros?',
      answer: `Over the last two years, Dobby service pros have maintained 98% positive reviews from our ${NUMBER_OF_JOBS} homeowners in two markets! We read thousands of reviews written by your neighbors and then hand-pick only the 5-star providers from your community. Then we spend a lot of time with them, often in person, to ensure they are ready for Dobby's hospitality standard. From highest quality craftsmanship to prompt responses to fair pricing - our service providers are known for providing an exceptional customer experience.`
    },
    {
      question: 'How do I know the price?',
      answer:
        'Once you submit your job, we assign one of our pre-vetted, top-quality pros to get it done. And they respond in the app to give you a detailed quote. Everything is on the app to verify and validate. You can ask as many questions as you want.'
    },
    {
      question:
        'What makes Dobby different from other home services companies?',
      answer:
        "As soon as you book a job on Angi, Thumbtack, or any home services matchmaking website, they make money by selling your job to multiple pros. So, they have zero incentive to find the best service pro to complete your job with 5-star quality. They sell your personal data, they don't solve your problem, and they exploit small business owners from your community. We founded Dobby to bring delightful experiences to homeowners and stop the exploitations of service pros. We've made managing your home faster, easier, and smarter."
    }
  ]
};
