import fernando from 'static/images/reviews/pro-fernando.webp';
import walter from 'static/images/reviews/pro-walter-oviedo.webp';
import anthony from 'static/images/reviews/pro-anthony-vito.webp';
import marc from 'static/images/reviews/pro-marc-leon.webp';
import akeem from 'static/images/reviews/pro-akeem-ali.webp';
import dionte from 'static/images/reviews/pro-dionte-jackson.webp';
import sam from 'static/images/reviews/pro-sam.webp';

export const reasons = {
  TYLER: {
    TYPE: 'Fernando',
    IMAGE: fernando,
    NAME: 'Fernando Acosta',
    POSITION: 'Handyman Services',
    TITLE: 'Dobby helped me grow my business completely cost-free.',
    TEXT:
      'I\'m Fernando, a handyman and renovation pro with experience in both the residential and commercial sectors. After five years of working with multiple local businesses, I started my own company. With Dobby\'s support in marketing, tech, and back office, I am proud to be in the driver seat now. \n\n"Fernando was efficient, knowledgeable, and reliable. He was a pleasure to work with!" - Heidi.',
    ALT: 'Fernando Acosta, Dobby expert in handyman services.'
  },
  SAM: {
    TYPE: 'Sam',
    IMAGE: sam,
    NAME: 'Sam Sheibani',
    POSITION: 'Renovation',
    TITLE: "I don't spend any time or money finding leads.",
    TEXT:
      'Hi! I\'m Sam and I\'m your go-to expert for kitchen and bathroom remodeling across the DMV. Specializing in elegant and functional kitchen transformations and luxurious bathroom makeovers, me and my team bring unparalleled expertise to every project. My team of seasoned professionals is dedicated to delivering top-notch craftsmanship, utilizing the latest design trends and sustainable materials.\n\n"Sam took my half-baked vision of what I wanted and executed artfully. His team’s skill and attention to detail combined with his acumen and excellent communication made a potential nightmare renovation in a 100+ year old building a breeze. Five stars!" - Mike.',
    ALT: 'Sam Sheibani, Dobby expert in renovation services.'
  },
  // WALTER: {
  // TYPE: 'Walter',
  // IMAGE: walter,
  // NAME: 'Walter Oviedo',
  // POSITION: 'General Contracting',
  // TITLE: "I don't spend any time or money finding leads.",
  // TEXT:
  // "I'm Walter. I own Mario's Home Improvement, LLC. My team can handle any projects in your home: from drywall, painting, plumbing, and electrical to wallpaper and tile needs. And we regularly manage big projects that require county permits and inspections. Whether you're looking for a minor fix or a substantial renovation, I'm your guy.\n\n\"Flawless installation of a complex patterned wallpaper. And, Walter was able to accommodate my request at very short notice.\" - Vrinda G."
  // },
  ANTHONY: {
    TYPE: 'Anthony',
    IMAGE: anthony,
    NAME: 'Anthony Vito',
    POSITION: 'Plumbing & HVAC',
    TITLE:
      'Dobby\'s "one job, one pro" practice helps me win more jobs, more homeowners.',
    TEXT:
      "I'm Anthony Vito, and I own our 4th generation family business: Vito Services. My great grandfather, James Vito Sr., opened Vito Services down on Kennedy Street in DC in 1934. Soon, the one-person shop grew into a family business with a service area spanning hundreds of miles. Our company was a founding participant in Washington DC's Lead Pipe Replacement Assistance Program (LPRAP) and we are proud to serve our community.\n\n\"I needed a second opinion on some issues. I'm so glad I did. This technician was knowledgeable and could provide answers to multiple questions that made sense.\" - Julie M.",
    ALT: 'Anthony Vito, Dobby expert in plumbing and HVAC services.'
  },
  // JOSE: {
  //   TYPE: 'Jose',
  //   // IMAGE: JoseImg,
  //   NAME: 'Jose Jaco',
  //   POSITION: 'Handyman Services',
  //   TITLE: 'After 2+ years of working together, Dobby is family!',
  //   TEXT:
  //     "I'm Jose, and I've worked for more than 14 years as a handyman in the DMV area. I work on all general handyman jobs in your home, including carpentry, drywall, painting, and vinyl plank. I install cabinets, sinks, and toilets. I've lived in Washington, DC area for 12 years, and Cactus Cantina is my favorite restaurant.\n\n\"Jose was extremely professional and easy to work with! He was not only on time, but was clear on the work that needed to be done. I felt Jose and his team went above and beyond, especially after it turned out that some extra work needed to be done, he really came through!\" - Pooja"
  // },
  MARC: {
    TYPE: 'Marc',
    IMAGE: marc,
    NAME: 'Marc Leon',
    POSITION: 'Inspections',
    TITLE:
      'Dobby is an exclusive, invite-only network of highest quality pros where I feel valued.',
    TEXT:
      'I\'m Marc, a master home inspector, and I have been running a construction business for more than 25 years now. I complete hundreds of home inspections every year through my company FSI Inspections. After completing my degree in civil engineering and architecture, I started my career in the US Army, and now I live in Alexandria. One of my favorite things in the world is taking my boys to Nationals games.\n\n"Marc was so knowledgeable about everything related to my new home! I just contacted him again 3 months after I purchased my home and he still shares his expertise with me! I am so grateful!" - Kimberly.',
    ALT: 'Marc Leon, Dobby expert in home inspections.'
  },
  DIONTE: {
    TYPE: 'Dionte',
    IMAGE: dionte,
    NAME: 'Dionte Jackson',
    POSITION: 'Pest Control',
    // TITLE: 'Dobby saves me time and helps me make more money faster',
    TITLE:
      "From marketing to customer support, Dobby's pro success team helps me manage my business.",
    TEXT:
      'I\'m Dionte, and I\'ve worked in the pest control industry for over 11 years. I grew up in the DMV area and now own Home Advantage Pest Services. My crew can handle most uninvited guests, including rodents, fleas, bed bugs, mosquitos, and more. We use eco-friendly materials to eradicate any intruders.\n\n"Dionte was great. Showed up promptly, worked efficiently, was very knowledgeable and explained the process. I would definitely recommend him!" - Charles.',
    ALT: 'Dionte Jackson, Dobby expert in pest control services.'
  }
  // AKEEM: {
  // TYPE: 'Akeem',
  // IMAGE: akeem,
  // NAME: 'Akeem Ali',
  // POSITION: 'Electrical Services',
  // TITLE:
  // "From marketing to customer support, Dobby's pro success team helps me manage my business.",
  // TEXT:
  // "I'm Akeem and I have over 18 years of experience in electrical work. I am proud to own and operate my own company, BSquare Electric and I am working towards becoming a Master Electrician. When I'm not working, I love watching movies with my family. \n\n\"Akeem is amazing! Thorough with his work and fast too. I wouldn't even think to reach out to anyone else for my electrical issues anymore.\" - Sinafik G."
  // }
};
