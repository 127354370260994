import React, { useState, useCallback, useRef } from 'react';
import { useTransition } from 'react-spring';
import { Element } from 'react-scroll';

import foregroundImage from 'static/images/backgrounds/phone-frame.webp';
import arrowImage from 'static/images/icons/arrow-right.svg';
import { AnimatedPhone, Dots, FadeText } from './components';

import {
  AnimatedDiv,
  Arrow,
  Arrows,
  FeatureImage,
  ForegroundImage,
  FlexContainer,
  FlexOne,
  FlexOneDescription,
  Section,
  Wrapper,
  PhoneWrapperDiv,
  Title
} from './styled';

import { howDobbyWorksSlides } from './enums';

const HowDobbyWorks = ({ initialSlide = howDobbyWorksSlides.SLIDE_1.TYPE }) => {
  const sectionRef = useRef(0);
  const [activeSlide, setActiveSlide] = useState(initialSlide);
  const onClick = useCallback(type => setActiveSlide(type), []);
  const transitions = useTransition(activeSlide, p => p, {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(-100%)' }
  });

  const handleLeftArrowClick = () => {
    if (activeSlide === howDobbyWorksSlides.SLIDE_1.TYPE) {
      setActiveSlide(howDobbyWorksSlides.SLIDE_3.TYPE);
    } else {
      setActiveSlide(prevSlide => prevSlide - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (activeSlide === howDobbyWorksSlides.SLIDE_3.TYPE) {
      setActiveSlide(howDobbyWorksSlides.SLIDE_1.TYPE);
    } else {
      setActiveSlide(prevSlide => prevSlide + 1);
    }
  };

  return (
    <Section ref={sectionRef}>
      <Wrapper>
        <Title>{`Lots to love.\nLess to manage.`}</Title>
        <Element name="how-it-works-3" />
        <FlexContainer>
          <FlexOne>
            <Dots
              onSlideChange={onClick}
              activeSlide={activeSlide}
              slides={Object.values(howDobbyWorksSlides)}
            />
          </FlexOne>
          <AnimatedPhone sectionRef={sectionRef}>
            <PhoneWrapperDiv>
              {transitions.map(({ item: type, props, key }) => {
                const slide = Object.values(howDobbyWorksSlides).find(
                  ({ TYPE }) => TYPE === type
                );
                return (
                  <AnimatedDiv style={props} key={`phone-image-${key}`}>
                    <FeatureImage
                      src={slide.IMAGE}
                      index={slide.index}
                      key={`item-${slide.TITLE}`}
                      alt={slide.TITLE}
                    />
                  </AnimatedDiv>
                );
              })}
            </PhoneWrapperDiv>
            <ForegroundImage
              src={foregroundImage}
              alt="Schedule the job and pay in the app"
            />
          </AnimatedPhone>
          <FlexOneDescription>
            <FadeText
              activeSlide={activeSlide}
              slides={Object.values(howDobbyWorksSlides)}
            />
          </FlexOneDescription>
        </FlexContainer>
        <Arrows>
          <Arrow onClick={handleLeftArrowClick} src={arrowImage} isLeft />
          <Arrow onClick={handleRightArrowClick} src={arrowImage} />
        </Arrows>
      </Wrapper>
    </Section>
  );
};

export default HowDobbyWorks;
