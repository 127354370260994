import React, { useEffect, useState } from 'react';

import { GetApp } from 'components';
import routes from 'common/enums/routes';

import homeQR from 'static/images/qr/home.webp';

import {
  Container,
  QRCode,
  QRCodeTitle,
  QRCodeAppTitle,
  QRWrapper,
  DownloadWrapper
} from './styled';

const DownloadQR = () => {
  const isHomeScreen = window.location.pathname === routes.HOME;
  const isLovePlannetScreen =
    window.location.pathname === routes.LOVE_OUR_PLANET;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [animate, setAnimate] = useState(!isHomeScreen);

  const isAnimate = isHomeScreen ? animate : true;

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 120) {
      setAnimate(true);
    } else {
      setAnimate(false);
    }
  }, [scrollPosition]);

  return (
    <Container
      scrollPosition={scrollPosition}
      isAnimate={isAnimate}
      isHomeScreen={isHomeScreen}
      isLovePlannetScreen={isLovePlannetScreen}>
      <DownloadWrapper>
        <GetApp light isCentered displaySingle />
      </DownloadWrapper>
      <QRWrapper>
        <QRCodeAppTitle>Open the App</QRCodeAppTitle>
        <QRCodeTitle>
          For estimates, customized property report, and getting your job done
          with 5-star guarantee
        </QRCodeTitle>
        <QRCode
          src={homeQR}
          alt="Scan QR code to download the Dobby home services app for estimates, property reports, and a 5-star service guarantee."
        />
      </QRWrapper>
    </Container>
  );
};

export default DownloadQR;
