import styled from 'styled-components';
import styles from 'common/enums/styles';

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 20px 0;
  * {
    font-family: HKGrotesk, 'sans-serif';
    font-size: 18px;
  }
`;

export const PageNumber = styled.button`
  margin: 0;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: ${props => (props.active ? `${styles.colors.PURE_BLACK}` : '#C0C1C7')};
  cursor: pointer;
  &:hover {
    color: ${styles.colors.PURE_BLACK};
  }
`;

export const Dots = styled.span`
  margin: 0;
  padding-bottom: 5px;
  color: #c0c1c7;
  font-weight: 700;
  font-size: 24px;
  border-radius: 50%;
  &:hover {
    color: ${styles.colors.PURE_BLACK};
  }
`;

export const Arrow = styled.button`
  margin: 0;
  padding: 5px 0;
  border: none;
  background: #fff;
  color: #c0c1c7;
  cursor: pointer;
  &:hover {
    color: ${styles.colors.PURE_BLACK};
  }
`;
