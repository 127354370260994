import React from 'react';
import TimelineObserver from 'react-timeline-animation';

import { Text } from 'components';
import styles from 'common/enums/styles';

import TimelineItem from '../TimelineItem/TimelineItem';
import {
  Item,
  TextContainer,
  Title,
  TextWrapper,
  ImageContainer,
  Image,
  ProName,
  Relative
} from './styled';

const ReasonsItem = ({
  title,
  name,
  position,
  image,
  text,
  reasonType,
  alt,
  isLast
}) => {
  const handleTimelineObserve = setObserver => (
    <TimelineItem setObserver={setObserver} id={reasonType} isLast={isLast} />
  );

  return (
    <Item>
      <TextContainer>
        <Title>{title}</Title>
        <TextWrapper>
          <Text color={styles.components.TEXT_INVERTED}>{text}</Text>
        </TextWrapper>
      </TextContainer>
      <Relative>
        <TimelineObserver
          initialColor={styles.colors.WHITE_NEW}
          fillColor={styles.components.TIMELINE}
          handleObserve={handleTimelineObserve}
        />
      </Relative>
      <ImageContainer>
        <Image src={image} alt={alt || name} />
        <ProName>{name}</ProName>
        <Text text1 color={styles.components.TEXT_INVERTED}>
          {position}
        </Text>
      </ImageContainer>
    </Item>
  );
};
export default ReasonsItem;
