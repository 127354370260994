import marc from 'static/images/reviews/pro-marc-leon.webp';
import akeem from 'static/images/reviews/pro-akeem-ali.webp';
import anthony from 'static/images/reviews/pro-anthony-vito.webp';
import hector from 'static/images/reviews/pro-hector.webp';
import fernando from 'static/images/reviews/pro-fernando.webp';
import konde from 'static/images/reviews/pro-konde.webp';
import robert from 'static/images/reviews/pro-robert.webp';

export const reviewsSection = {
  TITLE: 'Happy. Homeowners. Forever.',
  SUBTITLE:
    "We've served over <strong>3000</strong> homeowners in two markets and <strong>98%</strong> of our jobs are rated <strong>five stars</strong>. In an industry full of distrust and poor quality, Dobby is an epic love story between our homeowners and our service pros."
};

export const reviews = {
  REVIEW_1: {
    TEXT:
      'Fernando was efficient, knowledgeable, and reliable. He was a pleasure to work with!',
    PRO_NAME: 'Fernando',
    TYPE: 'review_1',
    PRO_OCUPATION: 'Handyman',
    HO_NAME: 'Heidi',
    HO_LOCATION: 'Washington, DC',
    PRO_IMAGE: fernando,
    ALT:
      'Local best handyman Fernando, home services provider in Washington, DC.'
  },
  REVIEW_2: {
    TEXT:
      'He was very helpful in helping me understand what needed to be done for my electrical project. He listed my options and gave his honest opinion.',
    PRO_NAME: 'Akeem',
    TYPE: 'review_2',
    PRO_OCUPATION: 'Electrical Services',
    HO_NAME: 'Kristin',
    HO_LOCATION: 'Washington, DC',
    PRO_IMAGE: akeem,
    ALT: 'Akeem Ali, Dobby expert in electrical services.'
  },
  REVIEW_3: {
    TEXT:
      'They were very flexible and provided superior service. I would love to work with them again in the future.',
    PRO_NAME: 'Vito',
    TYPE: 'review_3',
    PRO_OCUPATION: 'Plumbing & HVAC',
    HO_NAME: 'Shaun',
    HO_LOCATION: 'Washington, DC',
    PRO_IMAGE: anthony,
    ALT: 'Anthony Vito, Dobby expert in plumbing and HVAC services.'
  },
  REVIEW_4: {
    TEXT:
      'Hector did a great job with updating my backyard. He was professional, timely, and attentive. He even met me at the garden center to show me the different varieties of plants. I appreciate that level of attention and commitment to high quality work.',
    PRO_NAME: 'Hector',
    TYPE: 'review_4',
    PRO_OCUPATION: 'Landscaping',
    HO_NAME: 'Scott',
    HO_LOCATION: 'Washington, DC',
    PRO_IMAGE: hector,
    ALT: 'Hector, Dobby expert in landscaping services.'
  },
  REVIEW_5: {
    TEXT:
      'This is the second time Robert has helped us. He did quality work and helped us out on a strict timeline. Great experience again.',
    PRO_NAME: 'Robert',
    TYPE: 'review_5',
    PRO_OCUPATION: 'Drywall',
    HO_NAME: 'Seth',
    HO_LOCATION: 'Annandale, VA',
    PRO_IMAGE: robert,
    ALT: 'Robert, Dobby expert in drywall services.'
  },
  REVIEW_6: {
    TEXT:
      'Marc has been a pleasure to work with any time we need inspection support. His knowledge, expertise, and patience as we ask questions throughout our visits is much appreciated.',
    PRO_NAME: 'Marc',
    TYPE: 'review_6',
    PRO_OCUPATION: 'Inspections',
    HO_NAME: 'James',
    HO_LOCATION: 'Rockville, MD',
    PRO_IMAGE: marc,
    ALT: 'Marc Leon, Dobby expert in inspection services.'
  },
  REVIEW_7: {
    TEXT:
      'Super friendly, professional, fixed the problem quickly, and made friends with my cat — would definitely work with Mr. Konde again!',
    PRO_NAME: 'Konde',
    TYPE: 'review_7',
    PRO_OCUPATION: 'Electrical',
    HO_NAME: 'Kate',
    HO_LOCATION: 'Washington, DC',
    PRO_IMAGE: konde,
    ALT: 'Konde, Dobby expert in electrical services.'
  }
};

export const reviews_2 = reviews;
