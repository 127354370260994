import React, { useEffect } from 'react';
import { Element } from 'react-scroll';

import { connect } from 'react-redux';
import { selectUserId } from 'store/reducers/user';
import { openModal } from 'store/actions/modal';
import { pageTypes } from 'common/enums/pages';
import { Reviews, ImageQuote, DownloadAppImageText } from 'components';
import { analyticsPageTypes } from 'common/enums/pages';

import hero from 'static/images/hero/reviews@2x.webp';
import heroPlaceholder from 'static/images/hero/reviews@0.5x.webp';
import { imageQuoteContent, reviewsSection } from './enums';
import { reviews_2 as reviews } from 'common/enums/reviews';
import { HeroReview, ImageText, ListTimeline, Rating } from './components';

import { Section, DownloadImageWrapper } from './styled';

const ReviewsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Section>
      <HeroReview
        title="The most loved home services company."
        signature="This collage is made of real pictures of our service providers, who are the best in our community and the lifeblood of Dobby"
        subTitle="Redefining the most hated industry."
        imageSrc={hero}
        placeholderSrc={heroPlaceholder}
        backgroundType={pageTypes.REVIEWS}
        alt="Collage of home service professionals featured by Dobby, redefining the home services industry."
      />
      <Element name="scrollBelowSecondSection" />
      <ImageQuote
        image={imageQuoteContent.IMAGE}
        text={imageQuoteContent.TEXT}
        name={imageQuoteContent.NAME}
        alt={imageQuoteContent.ALT}
        type={pageTypes.REVIEWS}
      />
      <ListTimeline />
      <ImageText />
      <Rating reviews={reviews} />
      <Reviews
        type={pageTypes.REVIEWS}
        reviews={reviews}
        reviewsSection={reviewsSection}
        darkBackground
      />

      <DownloadImageWrapper>
        <DownloadAppImageText
          entryPoint={analyticsPageTypes.REVIEWS}
          inverted
        />
      </DownloadImageWrapper>
    </Section>
  );
};

const mapDispatchToProps = {
  openModal
};

const mapStateToProps = state => ({
  userId: selectUserId(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsPage);
